import React from "react";
import localforage from "localforage";
import { Auth0Provider } from "@auth0/auth0-react";

const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState?.returnTo || window.location.pathname
  );
};

const WrapWithProvider = ({ element }) => {
  // Configure localforage
  localforage.config({
    name: "Karina Grant DB",
  });

  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN}
      clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {element}
    </Auth0Provider>
  );
};

export default WrapWithProvider;
