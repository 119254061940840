// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-appointment-js": () => import("./../../../src/pages/appointment.js" /* webpackChunkName: "component---src-pages-appointment-js" */),
  "component---src-pages-black-friday-js": () => import("./../../../src/pages/black-friday.js" /* webpackChunkName: "component---src-pages-black-friday-js" */),
  "component---src-pages-bookings-distance-healing-form-js": () => import("./../../../src/pages/bookings/distance-healing/form.js" /* webpackChunkName: "component---src-pages-bookings-distance-healing-form-js" */),
  "component---src-pages-bookings-distance-healing-group-js": () => import("./../../../src/pages/bookings/distance-healing/group.js" /* webpackChunkName: "component---src-pages-bookings-distance-healing-group-js" */),
  "component---src-pages-bookings-distance-healing-group-thank-you-js": () => import("./../../../src/pages/bookings/distance-healing/group-thank-you.js" /* webpackChunkName: "component---src-pages-bookings-distance-healing-group-thank-you-js" */),
  "component---src-pages-bookings-distance-healing-js": () => import("./../../../src/pages/bookings/distance-healing.js" /* webpackChunkName: "component---src-pages-bookings-distance-healing-js" */),
  "component---src-pages-bookings-distance-healing-thank-you-js": () => import("./../../../src/pages/bookings/distance-healing/thank-you.js" /* webpackChunkName: "component---src-pages-bookings-distance-healing-thank-you-js" */),
  "component---src-pages-bookings-fhtj-healing-access-code-js": () => import("./../../../src/pages/bookings/fhtj/healing-access-code.js" /* webpackChunkName: "component---src-pages-bookings-fhtj-healing-access-code-js" */),
  "component---src-pages-bookings-fhtj-index-js": () => import("./../../../src/pages/bookings/fhtj/index.js" /* webpackChunkName: "component---src-pages-bookings-fhtj-index-js" */),
  "component---src-pages-bookings-fhtj-thank-you-healing-access-code-js": () => import("./../../../src/pages/bookings/fhtj/thank-you-healing-access-code.js" /* webpackChunkName: "component---src-pages-bookings-fhtj-thank-you-healing-access-code-js" */),
  "component---src-pages-bookings-fhtj-thank-you-js": () => import("./../../../src/pages/bookings/fhtj/thank-you.js" /* webpackChunkName: "component---src-pages-bookings-fhtj-thank-you-js" */),
  "component---src-pages-chakra-balancing-collection-index-js": () => import("./../../../src/pages/chakra-balancing-collection/index.js" /* webpackChunkName: "component---src-pages-chakra-balancing-collection-index-js" */),
  "component---src-pages-coaching-consultation-js": () => import("./../../../src/pages/coaching-consultation.js" /* webpackChunkName: "component---src-pages-coaching-consultation-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-corporate-wellness-index-js": () => import("./../../../src/pages/corporate-wellness/index.js" /* webpackChunkName: "component---src-pages-corporate-wellness-index-js" */),
  "component---src-pages-download-audio-js": () => import("./../../../src/pages/download-audio.js" /* webpackChunkName: "component---src-pages-download-audio-js" */),
  "component---src-pages-energy-healing-js": () => import("./../../../src/pages/energy-healing.js" /* webpackChunkName: "component---src-pages-energy-healing-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-events-permissioning-register-js": () => import("./../../../src/pages/events/permissioning-register.js" /* webpackChunkName: "component---src-pages-events-permissioning-register-js" */),
  "component---src-pages-events-quantum-touch-js": () => import("./../../../src/pages/events/quantum-touch.js" /* webpackChunkName: "component---src-pages-events-quantum-touch-js" */),
  "component---src-pages-events-quantum-touch-register-js": () => import("./../../../src/pages/events/quantum-touch-register.js" /* webpackChunkName: "component---src-pages-events-quantum-touch-register-js" */),
  "component---src-pages-events-register-js": () => import("./../../../src/pages/events/register.js" /* webpackChunkName: "component---src-pages-events-register-js" */),
  "component---src-pages-events-thank-you-js": () => import("./../../../src/pages/events/thank-you.js" /* webpackChunkName: "component---src-pages-events-thank-you-js" */),
  "component---src-pages-feedback-index-js": () => import("./../../../src/pages/feedback/index.js" /* webpackChunkName: "component---src-pages-feedback-index-js" */),
  "component---src-pages-feedback-thank-you-js": () => import("./../../../src/pages/feedback/thank-you.js" /* webpackChunkName: "component---src-pages-feedback-thank-you-js" */),
  "component---src-pages-fhtj-5-mhs-recording-js": () => import("./../../../src/pages/fhtj/5mhs/recording.js" /* webpackChunkName: "component---src-pages-fhtj-5-mhs-recording-js" */),
  "component---src-pages-fhtj-aac-live-upgrades-js": () => import("./../../../src/pages/fhtj/aac/live-upgrades.js" /* webpackChunkName: "component---src-pages-fhtj-aac-live-upgrades-js" */),
  "component---src-pages-fhtj-aac-recording-js": () => import("./../../../src/pages/fhtj/aac/recording.js" /* webpackChunkName: "component---src-pages-fhtj-aac-recording-js" */),
  "component---src-pages-fhtj-cbr-recording-js": () => import("./../../../src/pages/fhtj/cbr/recording.js" /* webpackChunkName: "component---src-pages-fhtj-cbr-recording-js" */),
  "component---src-pages-fhtj-complete-body-rewind-b-js": () => import("./../../../src/pages/fhtj/complete-body-rewind/b.js" /* webpackChunkName: "component---src-pages-fhtj-complete-body-rewind-b-js" */),
  "component---src-pages-fhtj-complete-body-rewind-recording-js": () => import("./../../../src/pages/fhtj/complete-body-rewind/recording.js" /* webpackChunkName: "component---src-pages-fhtj-complete-body-rewind-recording-js" */),
  "component---src-pages-fhtj-healing-access-codes-recording-js": () => import("./../../../src/pages/fhtj/healing-access-codes/recording.js" /* webpackChunkName: "component---src-pages-fhtj-healing-access-codes-recording-js" */),
  "component---src-pages-fhtj-heartwallaccesscodes-r-js": () => import("./../../../src/pages/fhtj/heartwallaccesscodes/r.js" /* webpackChunkName: "component---src-pages-fhtj-heartwallaccesscodes-r-js" */),
  "component---src-pages-fhtj-htb-recording-js": () => import("./../../../src/pages/fhtj/htb/recording.js" /* webpackChunkName: "component---src-pages-fhtj-htb-recording-js" */),
  "component---src-pages-fhtj-ias-recording-js": () => import("./../../../src/pages/fhtj/ias/recording.js" /* webpackChunkName: "component---src-pages-fhtj-ias-recording-js" */),
  "component---src-pages-fhtj-ias-supercharger-js": () => import("./../../../src/pages/fhtj/ias/supercharger.js" /* webpackChunkName: "component---src-pages-fhtj-ias-supercharger-js" */),
  "component---src-pages-fhtj-idealweight-recording-js": () => import("./../../../src/pages/fhtj/idealweight/recording.js" /* webpackChunkName: "component---src-pages-fhtj-idealweight-recording-js" */),
  "component---src-pages-fhtj-mhg-class-js": () => import("./../../../src/pages/fhtj/mhg/class.js" /* webpackChunkName: "component---src-pages-fhtj-mhg-class-js" */),
  "component---src-pages-fhtj-mhg-recording-js": () => import("./../../../src/pages/fhtj/mhg/recording.js" /* webpackChunkName: "component---src-pages-fhtj-mhg-recording-js" */),
  "component---src-pages-fhtj-qha-mentoring-js": () => import("./../../../src/pages/fhtj/qha/mentoring.js" /* webpackChunkName: "component---src-pages-fhtj-qha-mentoring-js" */),
  "component---src-pages-fhtj-qha-recording-js": () => import("./../../../src/pages/fhtj/qha/recording.js" /* webpackChunkName: "component---src-pages-fhtj-qha-recording-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-genuine-dining-js": () => import("./../../../src/pages/genuine-dining.js" /* webpackChunkName: "component---src-pages-genuine-dining-js" */),
  "component---src-pages-gift-js": () => import("./../../../src/pages/gift.js" /* webpackChunkName: "component---src-pages-gift-js" */),
  "component---src-pages-happivize-hna-recording-js": () => import("./../../../src/pages/happivize/hna/recording.js" /* webpackChunkName: "component---src-pages-happivize-hna-recording-js" */),
  "component---src-pages-happivize-qsr-recording-js": () => import("./../../../src/pages/happivize/qsr/recording.js" /* webpackChunkName: "component---src-pages-happivize-qsr-recording-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jazzup-ba-recording-js": () => import("./../../../src/pages/jazzup/ba/recording.js" /* webpackChunkName: "component---src-pages-jazzup-ba-recording-js" */),
  "component---src-pages-lander-211121-js": () => import("./../../../src/pages/lander/211121.js" /* webpackChunkName: "component---src-pages-lander-211121-js" */),
  "component---src-pages-lander-omnom-quantum-touch-level-1-js": () => import("./../../../src/pages/lander/omnom-quantum-touch-level-1.js" /* webpackChunkName: "component---src-pages-lander-omnom-quantum-touch-level-1-js" */),
  "component---src-pages-lander-sleep-offer-1-js": () => import("./../../../src/pages/lander/sleep/offer1.js" /* webpackChunkName: "component---src-pages-lander-sleep-offer-1-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-mindfulness-collection-offer-js": () => import("./../../../src/pages/mindfulness-collection-offer.js" /* webpackChunkName: "component---src-pages-mindfulness-collection-offer-js" */),
  "component---src-pages-mindfulness-meditation-phoneline-js": () => import("./../../../src/pages/mindfulness-meditation-phoneline.js" /* webpackChunkName: "component---src-pages-mindfulness-meditation-phoneline-js" */),
  "component---src-pages-mqm-live-calls-index-js": () => import("./../../../src/pages/mqm/live-calls/index.js" /* webpackChunkName: "component---src-pages-mqm-live-calls-index-js" */),
  "component---src-pages-my-account-forgot-password-js": () => import("./../../../src/pages/my-account/forgot-password.js" /* webpackChunkName: "component---src-pages-my-account-forgot-password-js" */),
  "component---src-pages-my-account-index-js": () => import("./../../../src/pages/my-account/index.js" /* webpackChunkName: "component---src-pages-my-account-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-payment-success-js": () => import("./../../../src/pages/payment-success.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-permissioning-and-transformation-hand-to-heart-js": () => import("./../../../src/pages/permissioning-and-transformation/hand-to-heart.js" /* webpackChunkName: "component---src-pages-permissioning-and-transformation-hand-to-heart-js" */),
  "component---src-pages-permissioning-and-transformation-history-js": () => import("./../../../src/pages/permissioning-and-transformation/history.js" /* webpackChunkName: "component---src-pages-permissioning-and-transformation-history-js" */),
  "component---src-pages-permissioning-and-transformation-index-js": () => import("./../../../src/pages/permissioning-and-transformation/index.js" /* webpackChunkName: "component---src-pages-permissioning-and-transformation-index-js" */),
  "component---src-pages-permissioning-and-transformation-relax-js": () => import("./../../../src/pages/permissioning-and-transformation/relax.js" /* webpackChunkName: "component---src-pages-permissioning-and-transformation-relax-js" */),
  "component---src-pages-permissioning-and-transformation-reset-js": () => import("./../../../src/pages/permissioning-and-transformation/reset.js" /* webpackChunkName: "component---src-pages-permissioning-and-transformation-reset-js" */),
  "component---src-pages-permissioning-and-transformation-reset-nervous-system-index-js": () => import("./../../../src/pages/permissioning-and-transformation/reset-nervous-system/index.js" /* webpackChunkName: "component---src-pages-permissioning-and-transformation-reset-nervous-system-index-js" */),
  "component---src-pages-permissioning-and-transformation-sleep-js": () => import("./../../../src/pages/permissioning-and-transformation/sleep.js" /* webpackChunkName: "component---src-pages-permissioning-and-transformation-sleep-js" */),
  "component---src-pages-permissioning-and-transformation-stress-depression-anxiety-js": () => import("./../../../src/pages/permissioning-and-transformation/stress-depression-anxiety.js" /* webpackChunkName: "component---src-pages-permissioning-and-transformation-stress-depression-anxiety-js" */),
  "component---src-pages-permissioning-and-transformation-what-is-permissioning-transformation-js": () => import("./../../../src/pages/permissioning-and-transformation/what-is-permissioning-transformation.js" /* webpackChunkName: "component---src-pages-permissioning-and-transformation-what-is-permissioning-transformation-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-pt-js": () => import("./../../../src/pages/pt.js" /* webpackChunkName: "component---src-pages-pt-js" */),
  "component---src-pages-quantum-brain-healing-appointment-js": () => import("./../../../src/pages/quantum-brain-healing-appointment.js" /* webpackChunkName: "component---src-pages-quantum-brain-healing-appointment-js" */),
  "component---src-pages-quantum-touch-an-interview-with-richard-gordon-founder-of-quantum-touch-js": () => import("./../../../src/pages/quantum-touch/an-interview-with-richard-gordon-founder-of-quantum-touch.js" /* webpackChunkName: "component---src-pages-quantum-touch-an-interview-with-richard-gordon-founder-of-quantum-touch-js" */),
  "component---src-pages-quantum-touch-canada-resonance-js": () => import("./../../../src/pages/quantum-touch/canada-resonance.js" /* webpackChunkName: "component---src-pages-quantum-touch-canada-resonance-js" */),
  "component---src-pages-quantum-touch-collective-lightspace-js": () => import("./../../../src/pages/quantum-touch/collective-lightspace.js" /* webpackChunkName: "component---src-pages-quantum-touch-collective-lightspace-js" */),
  "component---src-pages-quantum-touch-debbie-giles-qualified-quantum-touch-practitioner-js": () => import("./../../../src/pages/quantum-touch/debbie-giles-qualified-quantum-touch-practitioner.js" /* webpackChunkName: "component---src-pages-quantum-touch-debbie-giles-qualified-quantum-touch-practitioner-js" */),
  "component---src-pages-quantum-touch-energy-boost-workshop-js": () => import("./../../../src/pages/quantum-touch/energy-boost-workshop.js" /* webpackChunkName: "component---src-pages-quantum-touch-energy-boost-workshop-js" */),
  "component---src-pages-quantum-touch-free-talk-js": () => import("./../../../src/pages/quantum-touch/free-talk.js" /* webpackChunkName: "component---src-pages-quantum-touch-free-talk-js" */),
  "component---src-pages-quantum-touch-level-1-slipped-disc-back-into-place-js": () => import("./../../../src/pages/quantum-touch/level-1-slipped-disc-back-into-place.js" /* webpackChunkName: "component---src-pages-quantum-touch-level-1-slipped-disc-back-into-place-js" */),
  "component---src-pages-quantum-touch-level-1-testimonial-auror-js": () => import("./../../../src/pages/quantum-touch/level-1-testimonial-auror.js" /* webpackChunkName: "component---src-pages-quantum-touch-level-1-testimonial-auror-js" */),
  "component---src-pages-quantum-touch-level-1-testimonial-joanna-pain-relief-fibromyalgia-js": () => import("./../../../src/pages/quantum-touch/level-1-testimonial-joanna-pain-relief-fibromyalgia.js" /* webpackChunkName: "component---src-pages-quantum-touch-level-1-testimonial-joanna-pain-relief-fibromyalgia-js" */),
  "component---src-pages-quantum-touch-level-1-testimonial-raine-dunn-js": () => import("./../../../src/pages/quantum-touch/level-1-testimonial-raine-dunn.js" /* webpackChunkName: "component---src-pages-quantum-touch-level-1-testimonial-raine-dunn-js" */),
  "component---src-pages-quantum-touch-level-2-testimonials-js": () => import("./../../../src/pages/quantum-touch/level-2-testimonials.js" /* webpackChunkName: "component---src-pages-quantum-touch-level-2-testimonials-js" */),
  "component---src-pages-quantum-touch-mentoring-program-js": () => import("./../../../src/pages/quantum-touch/mentoring-program.js" /* webpackChunkName: "component---src-pages-quantum-touch-mentoring-program-js" */),
  "component---src-pages-quantum-touch-piano-before-after-quantum-touch-js": () => import("./../../../src/pages/quantum-touch/piano-before-after-quantum-touch.js" /* webpackChunkName: "component---src-pages-quantum-touch-piano-before-after-quantum-touch-js" */),
  "component---src-pages-quantum-touch-quantum-touch-frequently-asked-questions-js": () => import("./../../../src/pages/quantum-touch/quantum-touch-frequently-asked-questions.js" /* webpackChunkName: "component---src-pages-quantum-touch-quantum-touch-frequently-asked-questions-js" */),
  "component---src-pages-quantum-touch-quantum-touch-treatments-and-healing-js": () => import("./../../../src/pages/quantum-touch/quantum-touch-treatments-and-healing.js" /* webpackChunkName: "component---src-pages-quantum-touch-quantum-touch-treatments-and-healing-js" */),
  "component---src-pages-quantum-touch-supercharging-niagra-falls-example-js": () => import("./../../../src/pages/quantum-touch/supercharging-niagra-falls-example.js" /* webpackChunkName: "component---src-pages-quantum-touch-supercharging-niagra-falls-example-js" */),
  "component---src-pages-quantum-touch-testimonial-clare-green-nhs-nurse-js": () => import("./../../../src/pages/quantum-touch/testimonial-clare-green-nhs-nurse.js" /* webpackChunkName: "component---src-pages-quantum-touch-testimonial-clare-green-nhs-nurse-js" */),
  "component---src-pages-quantum-touch-the-history-of-self-created-health-js": () => import("./../../../src/pages/quantum-touch/the-history-of-self-created-health.js" /* webpackChunkName: "component---src-pages-quantum-touch-the-history-of-self-created-health-js" */),
  "component---src-pages-quantum-touch-what-is-quantum-touch-energy-healing-js": () => import("./../../../src/pages/quantum-touch/what-is-quantum-touch-energy-healing.js" /* webpackChunkName: "component---src-pages-quantum-touch-what-is-quantum-touch-energy-healing-js" */),
  "component---src-pages-quantum-touch-what-is-quantum-touch-used-for-js": () => import("./../../../src/pages/quantum-touch/what-is-quantum-touch-used-for.js" /* webpackChunkName: "component---src-pages-quantum-touch-what-is-quantum-touch-used-for-js" */),
  "component---src-pages-quantum-touch-what-is-self-created-health-js": () => import("./../../../src/pages/quantum-touch/what-is-self-created-health.js" /* webpackChunkName: "component---src-pages-quantum-touch-what-is-self-created-health-js" */),
  "component---src-pages-quantum-touch-what-is-supercharging-js": () => import("./../../../src/pages/quantum-touch/what-is-supercharging.js" /* webpackChunkName: "component---src-pages-quantum-touch-what-is-supercharging-js" */),
  "component---src-pages-quantum-touch-what-will-i-learn-on-a-quantum-touch-level-1-workshop-js": () => import("./../../../src/pages/quantum-touch/what-will-i-learn-on-a-quantum-touch-level-1-workshop.js" /* webpackChunkName: "component---src-pages-quantum-touch-what-will-i-learn-on-a-quantum-touch-level-1-workshop-js" */),
  "component---src-pages-quantum-touch-what-will-i-learn-on-a-quantum-touch-level-ii-workshop-js": () => import("./../../../src/pages/quantum-touch/what-will-i-learn-on-a-quantum-touch-level-ii-workshop.js" /* webpackChunkName: "component---src-pages-quantum-touch-what-will-i-learn-on-a-quantum-touch-level-ii-workshop-js" */),
  "component---src-pages-quantum-touch-who-should-learn-quantum-touch-js": () => import("./../../../src/pages/quantum-touch/who-should-learn-quantum-touch.js" /* webpackChunkName: "component---src-pages-quantum-touch-who-should-learn-quantum-touch-js" */),
  "component---src-pages-register-fhtj-js": () => import("./../../../src/pages/register-fhtj.js" /* webpackChunkName: "component---src-pages-register-fhtj-js" */),
  "component---src-pages-self-healing-mastery-index-js": () => import("./../../../src/pages/self-healing-mastery/index.js" /* webpackChunkName: "component---src-pages-self-healing-mastery-index-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-situlive-js": () => import("./../../../src/pages/situlive.js" /* webpackChunkName: "component---src-pages-situlive-js" */),
  "component---src-pages-spoon-therapy-not-spoon-bending-js": () => import("./../../../src/pages/spoon-therapy/not-spoon-bending.js" /* webpackChunkName: "component---src-pages-spoon-therapy-not-spoon-bending-js" */),
  "component---src-pages-success-event-booking-js": () => import("./../../../src/pages/success-event-booking.js" /* webpackChunkName: "component---src-pages-success-event-booking-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thank-you-signup-js": () => import("./../../../src/pages/thank-you-signup.js" /* webpackChunkName: "component---src-pages-thank-you-signup-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-the-essential-mindfulness-collection-index-js": () => import("./../../../src/pages/the-essential-mindfulness-collection/index.js" /* webpackChunkName: "component---src-pages-the-essential-mindfulness-collection-index-js" */),
  "component---src-pages-thebigretreat-js": () => import("./../../../src/pages/thebigretreat.js" /* webpackChunkName: "component---src-pages-thebigretreat-js" */),
  "component---src-pages-westfield-background-js": () => import("./../../../src/pages/westfield-background.js" /* webpackChunkName: "component---src-pages-westfield-background-js" */),
  "component---src-pages-what-is-energy-healing-js": () => import("./../../../src/pages/what-is-energy-healing.js" /* webpackChunkName: "component---src-pages-what-is-energy-healing-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-shop-post-archive-js": () => import("./../../../src/templates/shop-post-archive.js" /* webpackChunkName: "component---src-templates-shop-post-archive-js" */),
  "component---src-templates-shop-post-js": () => import("./../../../src/templates/shop-post.js" /* webpackChunkName: "component---src-templates-shop-post-js" */),
  "component---src-templates-tag-archive-js": () => import("./../../../src/templates/tag-archive.js" /* webpackChunkName: "component---src-templates-tag-archive-js" */)
}

